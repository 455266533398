<template>
  <div class="relative overflow-hidden">
    <Header />
    <main>
      <div class="mx-auto max-w-[540px]">
        <Intro />
      </div>
      <img
        src="@/assets/tears/green-desktop.png"
        class="hidden md:block w-full"
      />
      <img
        src="@/assets/tears/green-mobile.png"
        class="md:hidden w-full -mb-10"
      />

      <TeamVoting />
      <LastWinner />
      <Upsell />

      <Footer />
    </main>

    <Cookies />
  </div>
</template>

<script>
import Header from "./components/Header";
import Intro from "./components/Intro";
import TeamVoting from "./components/TeamVoting";
import Upsell from "./components/Upsell";
import LastWinner from "./components/LastWinner";
import Cookies from "./components/Cookies";
import Footer from "./components/Footer";

export default {
  name: "Homepage",
  components: {
    Header,
    Intro,
    TeamVoting,
    LastWinner,
    Upsell,
    Footer,
    Cookies,
  },
  data: function() {
    return {
      APIData: [],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.bit-4 {
  right: -29%;
  margin: -109px 0 0 0;
  width: 200px;
  height: auto;
}
.bit-desktop-4 {
  right: -150px;
  margin: -159px 0 0 0;
  width: 300px;
  height: auto;
}
</style>
